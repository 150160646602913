$astral: rgba(8, 11, 12, 1);
$astral1: rgb(18, 19, 29);
$purpleHeartBit: rgb(170, 98, 251);
$purpleHeart: rgba(130, 49, 221, 1);
$royalPurple: rgba(106, 51, 167, 1);
$scooter: rgba(57, 177, 215, 1);
$turquoiseBlue0: rgb(147, 246, 248);
$turquoiseBlue: rgba(92, 230, 233, 1);
$turquoiseBlue1: rgba(92, 230, 233, 0.5);
$pelorous: rgba(70, 181, 196, 1);
$shakespeare: rgba(72, 185, 216, 1);
$bostonBlue: rgba(58, 157, 184, 1);
$cadetBlue: #b2b2c7;
$easternBlue: rgba(23, 134, 164, 1);
$lightCyan: #cdfdfd;
$leanit101Blue: #06485d;
$leanit101BlueLight: #19a2cf;
$leanit101BlueLight2: #128eb8;
$leanit101BlueLight4: #0e7597;
$leanit101BlueLight3: rgb(11, 166, 218);
$leanit101Orange: #ff8010;
$navbarBorder: rgba(11, 166, 218, .3);
$navbarBorder2: rgba(11, 166, 218, .3);

$charade: #282839;
$charade2: #242432;
$cinder: #1a1a27; // navbar
$concret: #f2f2f2;
// $comet: black;
$comet: #606078;
$dolphin: #67677a;
$ebony: #13131f; ///backgound APP
$ebonyClay: #2c2c40;
$graySuit: #b3b3c8;
$gunPowder: #444456;
$gunPowderDisabled: rgba(68, 68, 86, 0.4);
$iron: rgba(231, 231, 232, 1);
$manatee: #88889c;
$martinique: #313148;
$martinique2: rgba(50, 68, 73, 0.42);
$mirage: #161624; //background modal
$steelGray:  #222230;
$steelGray2: #21212f;
$steelGray3: #191924;
$steelGray4: #1a1a27; // steps header modal
$steelGray5: #1e1e2c;
$steelGray6: #1f1f31;
$trout: #4a4a5b;
$trout2: #4c4c59;
$tuna: #373749;
$vulcan: #121220;
$vulcan1: #b2b2c4;
$vulcan2: hsla(240, 28%, 10%, 0.502);

$christi0: rgb(145, 182, 13);
$christi: rgba(130, 164, 12, 1);
$christi2: rgba(113, 146, 11, 1);
$LightgreenYellow: rgb(141, 160, 86);
$greenYellow: rgba(198, 251, 34, 1);
$greenYellow2: rgba(166, 197, 15, 1);
$greenYellow3: rgb(131, 156, 5);
$olivetone: rgba(95, 119, 12, 1);
$templateTag: rgb(155, 9, 155);
$templateTag2: rgb(245, 40, 245);

$pyramid-1: #c9d33a;
$errorBackground: rgb(172, 2, 2);
$errorMessage: rgb(165, 0, 0);
$danger: rgb(112, 0, 0);
$clearButton: rgb(172, 141, 2);


$white: #ffff;
$black: black;
